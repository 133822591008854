/**
 * Returns plain text as an array of paragraphs
 */
export function textToP(text: string) {
  if (text) {
    return text.split('\n\n');
  }

  return [];
}
