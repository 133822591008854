import { CapsizedText } from '@/components/text';
import { styled } from '@/stitches.config';
import Link from 'next/link';

type Props = {
  children: React.ReactNode;
  href: string;
  scroll?: boolean;
};

export function LinkWithArrow({ children, href, scroll = true }: Props) {
  return (
    <Link href={href} passHref scroll={scroll}>
      <Anchor>
        <Arrow>{'<-'}</Arrow>
        <CapsizedText>{children}</CapsizedText>
      </Anchor>
    </Link>
  );
}

const Arrow = styled(CapsizedText, {
  fontFeatureSettings: '"dlig" 1',
  transition: 'transform 0.12s ease-in-out',
});

const Anchor = styled('a', {
  display: 'inline-flex',
  gap: '$2',
  alignItems: 'center',
  color: '$foreground',
  borderBottom: 0,
  fontSize: '$2',
  textDecoration: 'none',
  margin: '0 $4 $2',

  '@bp3': {
    margin: '0 $6 $3',
  },

  '@bp4': {
    margin: '0 $8 $3',
  },

  svg: {
    '@motion': {
      transition: 'transform 0.2s',
    },
  },

  '&:hover': {
    [`${Arrow}`]: {
      transform: 'translateX(-$space$1)',
    },
  },
});
