import { Box } from '@/components/box';
import { ButtonLink } from '@/components/button-link';

type Props = {
  link: string;
  text: string;
  isInline?: boolean;
};

export function ExternalLink(props: Props) {
  const { link, text, isInline } = props;

  return (
    <Box>
      <ButtonLink isInline={isInline} link={link} type="external">
        {text}
      </ButtonLink>
    </Box>
  );
}
