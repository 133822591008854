import { Treemap } from '@/utils/createTreemapFromPalette';
import create from 'zustand';

interface CaseTransitionStore {
  navigateTo: string | null;
  treemap: Treemap | null;
  setCaseTransitionProps: (treemap: Treemap, url: string) => void;
  isCaseTransitionReady: () => boolean;
  resetCaseTransitionProps: () => void;
}

export const useCaseTransitionStore = create<CaseTransitionStore>(
  (set, get) => ({
    navigateTo: null,
    treemap: null,
    setCaseTransitionProps: (treemap, url) => set({ treemap, navigateTo: url }),
    isCaseTransitionReady: () => !!(get().treemap && get().navigateTo),
    resetCaseTransitionProps: () => set({ navigateTo: null, treemap: null }),
  })
);
