import { styled } from '@/stitches.config';

export const Container = styled('div', {
  margin: '0 $2',

  '@bp3': {
    margin: '0 $6',
  },

  '@bp4': {
    margin: '0 $8',
  },
});

Container.displayName = 'Container';
