import { Box } from '@/components/box';
import { Text } from '@/components/text';
import { styled } from '@/stitches.config';
import { textToP } from '@/utils/text-to-p';
import { Project } from '@/lib/sanity/queries/get-project';

type ProjectInfoProps = Pick<
  Project,
  'clients' | 'partners' | 'awards' | 'deliverables' | 'challenge' | 'value'
>;

interface ProjectInfoItemProps {
  label?: string;
  text?: string[] | string;
}

export function ProjectInfo({
  clients,
  partners,
  awards,
  deliverables,
  challenge,
  value,
}: ProjectInfoProps) {
  return (
    <Columns>
      <Section>
        <Overview>
          {clients.length > 0 && <Metadata items={clients} title="Client" />}

          {partners.length > 0 && (
            <Metadata items={partners} title="Partners" />
          )}

          {awards.length > 0 && <Metadata items={awards} title="Awards" />}

          {deliverables.length > 0 && (
            <Metadata items={deliverables} title="Deliverables" />
          )}
        </Overview>
      </Section>

      <HorizontalRuler />

      <Section>
        <ProjectInfoContainer>
          {challenge && (
            <ProjectInfoItem label="The challenge" text={textToP(challenge)} />
          )}

          {value && (
            <ProjectInfoItem label="Value delivered" text={textToP(value)} />
          )}
        </ProjectInfoContainer>
      </Section>
    </Columns>
  );
}

interface MetadataProps {
  title: string;
  items: {
    _id: string;
    name: string | null;
  }[];
}

function Metadata(props: MetadataProps) {
  const { title, items } = props;

  return (
    <dl>
      <Text as="dt" size="2" css={{ fontWeight: '$heavy', mb: 0 }}>
        {title}
      </Text>
      {items.map((el) => {
        if (!el.name) return null;

        return (
          <Text as="dd" size="2" css={{ mb: 0 }} key={el._id}>
            {el.name}
          </Text>
        );
      })}
    </dl>
  );
}

const Overview = styled('div', {
  width: '100%',
  maxWidth: '40rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridRowGap: '$7',

  '@bp1': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '$10',
  },

  '@bp3': {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridColumnGap: '$8',
    maxWidth: '25rem',
  },
});

const ProjectInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50rem',
  gap: '$6',

  // gridColumnGap: '$3',

  // '@bp4': {
  //   gridColumnGap: '$8',
  // },
});

function ProjectInfoItem({ label, text }: ProjectInfoItemProps) {
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text as="h2" size="2" css={{ fontWeight: '$heavy', mb: '$2' }}>
        {label}
      </Text>

      <Box>
        {Array.isArray(text) ? (
          text.map((t, i) => (
            <Text
              key={`p-${i}`}
              as="p"
              size={{
                '@initial': 2,
                '@bp4': 3,
              }}
              css={{
                // When there are 2 paragraphs or more
                '&:not(:first-child)': {
                  marginTop: '$8',
                },
              }}
            >
              {t}
            </Text>
          ))
        ) : (
          <Text
            size={{
              '@initial': 2,
              '@bp4': 3,
            }}
          >
            {text}
          </Text>
        )}
      </Box>
    </Box>
  );
}

const Section = styled('div', {});

const HorizontalRuler = styled('hr', {
  height: 1,
  background: '$borderColor',
  border: 'none',

  '@bp3': {
    display: 'none',
  },
});

const Columns = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  marginBottom: '$8',

  '@bp3': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: '$11',
    gridColumn: 'wide',
  },
});
