import { GridContainer } from '@/components/grid-container';
import { Text } from '@/components/text';
import { useHeaderStore } from '@/hooks/useHeaderStore';
import { urlForImage } from '@/lib/sanity/sanity';
import { item } from '@/lib/transitions';
import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import NextImage from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useEffectOnce, useIntersection } from 'react-use';
import { curve, delay, duration, yOffset } from '../enter-animaton';
import { Project } from '@/lib/sanity/queries/get-project';

type ProjectCoverProps = {
  projectCoverImage: Project['coverImage'];
  projectCoverVideo: Project['coverVideo'];
  projectDescription: string;
  projectTransitionColor: string | null;
};

type ProjectWrapperProps = {
  children?: React.ReactNode;
  description: string;
  color?: string;
};

const LAYOUT_TRANSITION_TIME = 0.8;

function ProjectCoverWrapper(props: ProjectWrapperProps) {
  const { children, description, color = '#999' } = props;

  /**
   * Checking the state whether the cover image/video is out of the view
   * The state is then saved in 'useCoverIntersection' store
   * To be used in the header component to change the colour of the Logo
   */
  const { setIsIntersectingWithProjectCover } = useHeaderStore();

  const intersectionOption = {
    root: null,
    rootMargin: '0px',
    treshold: 0.1,
  };

  // Checks if the ProjectCover component is over the white/dark part of the page so we can invert some colors
  const ref = useRef<HTMLDivElement | null>(null);
  const intersection = useIntersection(ref, intersectionOption);

  useEffect(() => {
    if (typeof intersection?.isIntersecting === 'boolean') {
      setIsIntersectingWithProjectCover(intersection.isIntersecting);
    }
  }, [intersection, setIsIntersectingWithProjectCover]);

  return (
    <Wrapper ref={ref} css={{ backgroundColor: color }}>
      {children}
      <motion.div variants={item}>
        <GridContainer
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            '@bp6': {
              paddingRight: '15vw',
            },
          }}
        >
          <Text
            as={motion.h1}
            size={{
              '@initial': 8,
              '@bp3': 11,
              '@bp5': 14,
            }}
            initial={{ opacity: 0, y: yOffset }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: duration,
              delay: delay + 0.2,
              ease: curve,
            }}
            css={{
              display: 'block',
              zIndex: 1,
              color: 'white',
              '@bp3': {
                gridColumn: 'wide',
              },
              // Targets desktops with a display configuration of 150% that have aprx 947px of view height
              // To ensure long case study titles are not too big and doesn't bleed into the header
              '@media only screen and (max-height: 940px)': {
                '@bp5': {
                  fontSize: '$10',
                },
              },
            }}
          >
            {description}
          </Text>
        </GridContainer>
      </motion.div>
    </Wrapper>
  );
}

export function ProjectCover(props: ProjectCoverProps) {
  const {
    projectCoverImage,
    projectDescription,
    projectCoverVideo,
    projectTransitionColor,
  } = props;

  /**
   * Ok. Here goes. What I actually wanted to do was give the animation with
   * a layoutId somethink like variants so I can orchestrate it using framer-motion
   * https://www.framer.com/docs/animation/##orchestration
   * However, I don't see a way to do this. Seems like motion.div components that use
   * a layoutId are somehow different than normal motion.divs that can use variants.
   *
   * I might be missing something here. Oh well.
   *
   * The way I solved the video component being loaded while the animation is not done yet,
   * is by simply hardcoding an extra render after the time it takes to animate the box.
   * So if the box transitions in 1 second, we wait 1 second before we trigger a new state
   * in React. delayHasPassed will be true and voila, you'll see your coverImage/coverVideo!
   *
   * Yes. This needs to be cleaned up. But we also need to release the website.
   *
   *
   */
  const [delayHasPassed, setDelayHasPassed] = useState(false);

  useEffectOnce(() => {
    const timer = setTimeout(() => {
      setDelayHasPassed(true);
    }, LAYOUT_TRANSITION_TIME * 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  if (projectCoverImage) {
    const imageUrl = urlForImage(projectCoverImage).url();

    if (imageUrl) {
      return (
        <ProjectCoverWrapper
          description={projectDescription}
          color={
            projectTransitionColor
              ? projectTransitionColor
              : projectCoverImage.metadata.palette.vibrant.background
          }
        >
          {delayHasPassed && (
            <>
              {projectCoverVideo ? (
                <motion.div
                  inherit={false}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 1,
                  }}
                >
                  <>
                    <CoverOverlay
                      css={{
                        zIndex: 1,
                      }}
                    />
                    <CoverVideo
                      src={projectCoverVideo}
                      controls={false}
                      autoPlay
                      loop
                      playsInline
                      muted
                    />
                  </>
                </motion.div>
              ) : (
                <motion.div
                  inherit={false}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                  }}
                >
                  <CoverOverlay />
                  <CoverImage
                    src={imageUrl}
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={projectCoverImage.metadata.lqip}
                  />
                </motion.div>
              )}
            </>
          )}
        </ProjectCoverWrapper>
      );
    }
  }

  return <ProjectCoverWrapper description={projectDescription} />;
}

const Wrapper = styled('div', {
  height: '80vh',
  position: 'relative',

  '&::after': {
    content: '',
    position: 'absolute',
    zIndex: '$default',
    top: 0,
    left: 0,
    right: 0,
    height: '$10',
  },
});

const CoverImage = styled(NextImage, {
  objectFit: 'cover',
  position: 'absolute',
  inset: 0,
});

const CoverVideo = styled('video', {
  objectFit: 'cover',
  position: 'absolute',
  height: '100%',
  width: '100%',
});

const CoverOverlay = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.2)',
  zIndex: 1,
});
